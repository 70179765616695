import axios from 'axios'
import { compressImage } from '../modules/CompressImage'
import imgTypeChange from '../modules/imgChange'
// 访问线上api时axios发送跨域请求时需要设置这个参数
const config = {
  timeout: 60000,
  withCredentials: true,
}
//本地调试端口6281  发布6280

const baseURL = {
  development: 'http://1.14.169.193:92', //http://1.14.169.193:92' //http://192.168.2.125:6281
  production: 'http://43.139.69.79:90', //https://www.rijinggs.com http://43.139.69.79:90/
  // production: 'http://1.14.169.193:92', //https://www.rijinggs.com http://43.139.69.79:90/
}
if (process.env.NODE_ENV === 'development') {
  // 开发环境
  config.baseURL = baseURL.development
} else if (process.env.NODE_ENV === 'production') {
  // 生产环境
  config.baseURL = baseURL.production
}
const axiosInstance = axios.create({
  baseURL: config.baseURL,
  validateRequest: (config) => {
    console.log(config);
    return true;
  }
})
// params 拼接在url的查询字符串参数
// data body参数
const get = function (api, data) {
  return axiosInstance({
    method: 'GET',
    url: api,
    params: data,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}
const deleteMethod = function (api, data) {
  return axiosInstance({
    method: 'DELETE',
    url: api,
    params: data,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}
const put = function (api, data) {
  return axiosInstance({
    method: 'PUT',
    url: api,
    data: data,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}
const getUserCode = function (api) {
  return axiosInstance({
    method: 'GET',
    url: api,
    responseType: 'blob',
    // headers: {
    //   Authorization:
    //     'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
    // },
  })
}
const post = function (api, data) {
  return axiosInstance({
    method: 'POST',
    url: api,
    data: data,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}
/**
 * //压缩图片上传方法
 * @param data {} t图片文件
 */
const postImg = async function (api, data) {
  let file = await imgTypeChange(data)
  let newfile = await compressImage(file)
  if (newfile.size > 10000) {
    newfile = await compressImage(newfile)
  }
  var formData = new FormData()
  formData.append('file', newfile, newfile.name)
  return axiosInstance({
    method: 'POST',
    url: api,
    data: formData,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}
axiosInstance.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    //console.log(response.data)
    return response
  },
  (error) => {
    console.log(error)
    return Promise.reject(error.response)
  }
)

export { get, post, getUserCode, deleteMethod, put, baseURL, postImg }
